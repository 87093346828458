import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/module/Hero";

import banner from "../assets/image/inner-page/png/modules/student-module-banner.png";
import Functionalities from "../sections/module/Functionalities";
import {
  BenefitData,
  FeatureData,
  FunctionalitiesData,
} from "../const/static-data";
import Features from "../sections/module/Features";
import Benefits from "../sections/module/Benefits";
import SectionTabs from "../sections/module/SectionTabs";
import ModuleImage from "../sections/module/ModuleImage";

const StudentModule = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero title={"Student"} banner={banner} />

        <SectionTabs />

        <section className="mt-15">
          <div className="container">
            <h2 className="font-size-8">About</h2>
            <p id="section2" className="gray-text font-size-6">
              By employing <span className="title">UNICLOUD 360</span>, one of
              the main stakeholders of your education institution- your
              students- can also utilize a student-centric portal to make their
              academic journey smoother and more efficient.{" "}
              <span className="title">UNICLOUD360</span> offers students tools
              and features that will allow them to keep track of their
              attendance, class schedules, assessments, finances and other
              important aspects of education at your school. This module
              centralizes all student engagement at a single point of access,
              ensuring the student has easier access to his or her information
              and lecturer or school communication mediums in the most
              innovative manner possible.
            </p>
          </div>
        </section>
        <Functionalities data={FunctionalitiesData.Student} />
        <Features data={FeatureData.Student} />
        <ModuleImage />
        <Benefits data={BenefitData.Student} />
        {/*<Team />*/}
        {/*<Stats />*/}
      </PageWrapper>
    </>
  );
};
export default StudentModule;
